﻿@import "_portal_variables";


.widget {
    position: relative;

    .watermark {
        font-size: 260px;
        top: -45px;
        right: -70px;
        position: absolute;
        color: theme-color('info');
        opacity: 0.15;
        z-index: 0;
        transform-origin: bottom;
        transform: rotate(-27deg);

        @include media-breakpoint-up(sm) {
            top: -65px;
            right: -50px;
            font-size: 200px;
            font-size: 1600%;
        }
    }

    /*.spin {
        animation: spin 240s linear infinite;
    }*/

    .tools, form {
        z-index: 10;
        position: relative;
    }

    .tools {
        margin-top: 0;
    }
}


//
// Unpaid and Overdue invoices
//
.inv-widget {
    .list {
        display: flex;
        flex-wrap: wrap;

        .item {
            display: flex;
            flex: 1 1 100%;
            margin: 0.5rem 1rem 1.5rem 1rem;
            flex-wrap: wrap;
        }

        @media screen and (min-width: 570px) and (max-width: 767px), 
                screen and (min-width: 850px) and (max-width: 1200px) 
        {
            // allows 2 invoice items per row
            .item {
                border-right: solid 1px $gray-500;
                flex-basis: 47%;
                max-width: 47%;
            }

            .item:nth-child(2n), .item:last-child {
                border-right: 0;
                margin-right: 0;
            }
        }


        @include media-breakpoint-only(xl) {
            // allows 3 invoice items per row
            .item {
                border-right: solid 1px $gray-500;
                flex-basis: 270px;
                max-width: 270px;
            }

            .item:nth-child(3n), .item:last-child {
                border-right: 0;
                margin-right: 0;
            }
        }



        .details {
            flex: 1 1 50%;
            display: flex;
            flex-wrap: wrap;

            .name {
                flex: 0 0 50px;
            }

            .value {
                flex: 1 1 calc(100% - 50px);
            }
        }

        .name {
            @include font-size($font-size-sm);
        }

        .total {
            flex: 1 1 50%;
            text-align: center;
        }

        .tools {
            display: flex;
            flex: 0 0 100%;
            justify-content: flex-end;
            @include font-size($font-size-sm);
            padding-right: 2rem;
            padding-top: 1rem;
        }
    }
}