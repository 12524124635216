﻿@import "support_variables";


.articlelist {
    ul {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 2rem;
        }
    }

    i {
        margin-right: 5px;
        font-size: 1.5rem;
        /*margin-left: -1.5rem;*/
    }

    h5 {
        //color: $headings-color;
        //font-weight: bold;
        margin-bottom: 0.5rem;
    }
}


.summary {
    padding: 0rem 1rem 2rem 2rem;
    font-style: italic;
    color: $gray-600;

    p {
        margin-bottom: 0.25rem
    }

    @include media-breakpoint-up(md) {
        padding-left: 0;
    }
}

.article{
    .content {
        blockquote {
            margin-left: 2rem;
            border-left: solid 3px theme-color("success");
            padding: 1rem;
            font-weight: bold;
        }
    }
}