﻿@import "support_variables";

@import "../../widgets/widget_pickable-tile";


.g-tileselect {

    .tile {
        label {
            padding-right: 3rem;
        }
    }

    label {

        i {
            position: absolute;
            right: 10px;
            bottom: 5px;
            font-size: 3rem;
            z-index: 0;
            opacity: .4;
        }
    }
}