﻿@import "../brand/variables";

.g-tileselect {

    .tile {

        label {
            background-color: $card-bg;
            border: solid $card-border-width $card-border-color;
            border-radius: $card-border-radius;
            box-shadow: rgba(0,0,0,.1) -1px 2px;
            position: relative;
            padding: 1rem 1rem 1rem 40px;
            //display: flex;
            //flex-direction: column;
            width: 100%;
            height: 100%;
            margin: 0;

            input {
                position: absolute;
                top: 1.3rem;
                left: 1.3rem;
            }

            .badge {
                left: auto;
                width: auto;
                padding-left: 1rem;
            }
        }

        &.k-state-active {
            label {
                border-color: theme-color('info');
            }
        }

        .title {
            color: $headings-color;
            font-family: $headings-font-family;
            font-weight: 400;
            font-size: $h3-font-size;
        }

        .title + p {
            margin-top: 0.5rem;
        }
    }
}