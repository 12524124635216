﻿@import "support_variables";

@import "../kendo/upload/layout";


.k-upload {
    border: none;

    .k-upload-files {
        border: none;

        .k-file {
            border: none;
            background-color: $gray-200;
            margin-bottom: 0.5rem;


            &.k-file-error {
                .k-i-warning {
                    color: theme-color('danger');
                }

                .k-file-extension-wrapper {
                    color: theme-color('warning');
                }
            }
        }

        .k-progress {
            background-color: theme-color('success');
        }

        .k-file-invalid-icon {
            background-color: #96342c;
            padding: 0 8px;
            border-radius: 25%;
            color: white;
            margin-left: -1rem;
            bottom: 2px;
            font-weight: bold;
        }

        .k-file-validation-message {
            color: theme-color('danger');
            font-size: $font-size-sm;
        }
    }

    .k-file-extension-wrapper {
        color: theme-color('info');
    }

    .k-file-invalid-extension-wrapper {
        color: $gray-600;
    }
}

