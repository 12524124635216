﻿@import "blog_variables";


/*.tabstrip-wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
}*/

.k-tabstrip-top > .k-tabstrip-items {
    border-bottom: 0;
}

.k-tabstrip {
    margin: 2rem 0;

    > .k-tabstrip-items {
        .k-item {
            .k-link {
                padding: .5rem 2rem;
            }
        }
    }

    li.k-state-active {
        background-color: $gray-800;
        color: #fff;
    }
}



/*.tabstrip {
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 3rem;
        padding: 1rem;
        cursor: pointer;

        &.k-state-active {
        }
    }
}*/