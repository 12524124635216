﻿@import "support_variables";


.catlist {
    // h2 have a 4rem margin which is too much on the top line
    // we need to pull the list up a little
    margin-top: -3rem;

    ul {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 0.5rem;
        }

        a {
            color: $body-color;

        }
    }

    h5 {
        line-height: 1.5;
    }

    .count {
        font-size: $font-size-sm;
        font-weight: normal;
        margin-left: 1rem;
    }
}