﻿@import "_portal_variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/badge";



.badge {
    background-color: theme-color('info');
    color: color-yiq(theme-color('info'));
    padding: 1px 3px 3px 3px;
    z-index: 1;
    /*font-family: "Open Sans", sans-serif;*/
    text-transform: uppercase;
    letter-spacing: 1.2pt;

    &.badge-warning {
        background-color: theme-color('warning');
        color: color-yiq(theme-color('warning'));
    }

    &.badge-danger {
        background-color: theme-color('danger');
        color: color-yiq(theme-color('danger'));
    }
}


.badged {
    position: relative;
    overflow: hidden;
    padding-top: 1rem;
    /*.badge {
        position: absolute;
        top: 1px;
        right: 1px;
    }*/
    .badge {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        text-align: right;

        &:after {
            content: ' ';
            background-color: theme-color('info');
            width: 60px;
            position: absolute;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            right: -16px;
            height: 16px;
            z-index: -1;
        }

        &.badge-warning {
            &:after {
                background-color: theme-color('warning');
            }
        }

        &.badge-danger {
            &:after {
                background-color: theme-color('danger');
            }
        }
    }

    &.btm-badge {
        .badge {
            top: auto;
            left: 0;
            bottom: 0;

            &:after {
                right: auto;
                left: -16px;
            }
        }
    }
}

ul.card-list {

    li.badged {
        padding-top: 3rem;
    }
}






.tag {
    width: 60px;
    position: absolute;
    padding: 3px 0 2px 0;
    font-size: x-small;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0;
    color: #fff;
    top: 5px;
    right: -16px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    text-align: center;
}

.tag-new {
    letter-spacing: 1px;
    background-color: theme-color("dark")
}

.tag-sale {
    background-color: theme-color("success");
    color: color-yiq(theme-color('success'));
}

.tag-hot {
    background-color: theme-color("danger");
}
