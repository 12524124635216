﻿@import "_portal_variables";

.dotoday {
    img {
        display: none;
    }

    .reason {
        margin-bottom: 2rem;

        h2 {
            margin-top: $card-spacer-x;
            margin-bottom: $card-spacer-x;
        }
    }

    &.full {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: $card-bg;
        background-clip: border-box;
        border: $card-border-width solid $card-border-color;
        @include border-radius($card-border-radius);
        padding: $card-spacer-x * 2;

        .options {
            display: flex;
        }

        .links, img {
            flex: 1 1 50%;
        }

        img {
            max-width: 252px;
        }
    }
}

@media only screen and (min-width : 600px) {
    .dotoday.full img {
        display: block;
    }
}