﻿@import "support_variables";


.split_container {
    .bgmgl {
        background-color: white;
    }

    .container {
        .content {
            background-color: white;
            padding-top: 3rem;
            padding-bottom: 3rem;

        }
    }
}
