﻿@import "support_variables";

$switch-size: 3rem;
$switch-border-radius: ($switch-size / 2) !default;
$switch-handle-size: 1.5rem;

.k-switch {
    @include border-radius($switch-border-radius);
    cursor: pointer;
    width: $switch-size * 2;
    background: 0 0;
    border: 0;
    outline: 0;
    display: inline-flex;
    overflow: hidden;
    vertical-align: middle;
    font-size: 1rem;
    user-select: none;
    text-align: left;
    height: 2.4rem;
    border-color: rgba(0,0,0,.15);
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);

    [type=checkbox] {
        position: absolute !important;
        top: 0;
        left: 0;
        margin: 0;
        z-index: -1;
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
    }
}

.k-switch, .k-switch-container, .k-switch-handle {
    box-sizing: border-box
}

.k-switch-container {
    @include border-radius($switch-border-radius);
    padding: 0 0;
    border-width: 0;
    border-style: solid;
    flex: 1 0 auto;
    position: relative;
    outline: 0;
    transition: background-color .2s ease-out 0s
}

.k-switch-handle {
    @include border-radius($switch-border-radius * 2);
    width: $switch-handle-size;
    height: 100%;
    border-width: 0 1px;
    border-style: solid;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: 0;
    transition: left .2s ease-out 0s;
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);
    background-color: $gray-300;
    /*background: $gray-300 linear-gradient(180deg, $gray-400, $gray-300) repeat-x;*/
}

.k-ie .k-switch-handle {
    display: block;
}

.k-switch-on .k-switch-handle {
    left: calc(100% - #{$switch-handle-size - 0.1rem});
}

.k-edge, .k-ie {
    .k-switch-on .k-switch-handle {
        left: 100%;
        margin-left: 0 - $switch-handle-size;
    }
}

.k-switch-off .k-switch-handle {
    left: -3px;
}

.k-switch-label-off, .k-switch-label-on {
    display: inline;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    line-height: 3em;
    overflow: hidden;
    left: 1rem;
}

.k-switch-label-on {
    color: #fff;
    text-align: left;
}

.k-switch-label-off {
    background: $gray-400;
    color: color-contrast(#000, $gray-400);
    text-align: left;
    padding-left: 1rem;
}

.k-rtl, k-switch[dir=rtl] {

    .k-switch.k-switch-on .k-switch-handle {
        left: 0
    }

    .k-switch.k-switch-off .k-switch-handle {
        left: calc(100% - #{$switch-handle-size - 0.1rem})
    }

    .k-switch .k-switch-label-on {
        text-align: right;
        left: initial;
        right: 8px
    }

    .k-switch .k-switch-label-off {
        text-align: left;
        left: 8px;
        right: initial
    }
}


.k-switch-container {
    box-shadow: inset 0 0 0 1px rgba(0,0,0,.08)
}

.k-switch-handle {
    background-clip: padding-box
}


.k-edge12, .k-edge13, .k-ie11 {
    .k-switch-handle {
        background-clip: border-box
    }
}


.k-switch-label-off, .k-switch-label-on {
    text-shadow: none
}

.k-switch-on {
    .k-switch-container {
        color: #fff;
        background-color: theme-color("success");
    }

    /*.k-switch-handle {
        border-color: rgba(0,0,0,.08);
        color: #fff;
        background-color: #f2f2f2
    }*/

    @supports (hover:hover) {
        &.k-state-hover, .k-switch-on:hover {
            .k-switch-container {
                color: #fff;
                background-color: #eb5b51
            }

            .k-switch-handle {
                border-color: rgba(0,0,0,.15);
                color: #fff;
                background-color: #ededed
            }
        }
    }

    .k-switch-label-off {
        color: transparent;
        background: transparent;
    }
}


.k-switch-off {
    .k-switch-container {
        color: #656565;
        background-color: #fff
    }

    /*.k-switch-handle {
        border-color: rgba(0,0,0,.08);
        color: #656565;
        background-color: #f2f2f2
    }*/

    @supports (hover:hover) {
        &.k-state-hover, .k-switch-on:hover {
            .k-switch-container {
                color: #656565;
                background-color: #fff
            }

            .k-switch-handle {
                border-color: rgba(0,0,0,.15);
                color: #656565;
                background-color: #ededed
            }
        }
    }

    .k-switch-label-on {
        color: transparent;
        background: transparent;
    }
}



.k-switch.k-state-focused, .k-switch:focus {
    .k-switch-container {
        outline: 0;
        box-shadow: inset 0 0 0 3px rgba(0,0,0,.06)
    }
}

.k-switch.k-state-hover, .k-switch:hover {
    .k-switch-container {
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.15)
    }
}


.k-switch.k-state-disabled {
    cursor: default;
    
    &.k-state-focused, &:focus {
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.08)
    }
}

.k-switch[aria-readonly=true] {
    pointer-events: none
}