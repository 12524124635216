﻿@import "support_variables";


dl.article-meta {
    display: flex;
    flex-wrap: wrap;

    dt {
        flex: 0 0 100px;
        max-width: 100px;
    }

    dd {
        flex-basis: calc(100% - 100px);
    }
}

textarea.autoexpand {
    height: 90px;
    max-height: 350px;
    padding: 1rem;
    overflow: hidden;
}