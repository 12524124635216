﻿@import "support_variables";


.tickets {

    section.list {
        margin-top: 5rem;

        header {
            /*display: table-header-group;*/
            /*background: $body-bg;*/
            margin: 0 0 2rem 0;
        }

        .btn {
            margin-top: -1rem;
        }
    }

    section.list:first-child {
        margin-top: 0;
    }


    table {
        tr {
            display: flex;
        }

        td, th {
            flex: 0 0 150px;
        }

        td.subject, th.subject {
            flex: 1 1 60%;
        }

        td.date, th.date {
            flex-basis: 100px;
        }
    }
}


.article-meta {
    font-size: 1.3rem;
    margin-right: 3rem;
    margin-bottom: 1.5em;

    i {
        margin-right: 4px;
    }

    & > div {
        display: inline-block;
        margin: 0 3rem 0 0;
    }

    .tags {
        a:after {
            content: ",";
        }

        a:last-child:after {
            content: "";
        }
    }
    /*.author {
        .avatar {
            //width: 32px;
            width: 24px;
            border-radius: 50%;
            margin: 0 5px 0 -20px;
            display: none;
        }
    }*/
}


.ticket-message {
    display: block;
    background-color: #fff;
    padding: $grid-gutter-width / 2;
    margin-bottom: 2rem;

    &.first {
        border-top-right-radius: 0.5rem;
    }

    &.from {
        &.first {
            border-top: solid 2px theme-color("success");
        }

        border-right: solid 2px theme-color("success");
    }

    &.to {
        &.first {
            border-top: solid 2px theme-color("info");
        }

        border-right: solid 2px theme-color("info");
    }
}

.ticket-status {
    padding: 0 $alert-padding-x;

    & > i {
        font-size: 3rem;
    }
}

.tk-inline-status {
    margin-bottom: 1rem;

    i {
        font-size: 3rem;
        margin-right: 1rem;
    }

    &.info {
        color: theme-color("info");
    }

    &.success {
        color: theme-color("success");
    }
}

.user-generated {
    ol, ul, dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: 0;
    }


    pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
    }

    .prettyprint {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: 10px;
        white-space: pre-wrap;
    }

    blockquote {
        margin: 14px 40px;
        padding: 2px 8px 2px 20px;
        font-style: italic;
        border-width: 0;
        border-left: solid 5px $gray-500;
    }


    element.style {
        width: 100%;
    }

    table.fr-table, .fr-table td, .fr-table th {
        border: 1px solid $gray-500;
    }


    .fr-table tr {
        display: table-row;
        flex: none;
    }

    .fr-table td {
        display: table-cell;
        flex: none;
    }
}

.ticket-update {
    margin-bottom: 2rem;

    textarea {
        width: 100%;
    }

    &.waiting {
        textarea {
            height: 45px;
        }
    }
}



form.with-selectable:not(.full) {
    .tile {
        .text-muted {
            display: none;
        }
    }
}

.ticket-page {

    .banner {
        text-align: center;
        font-style: italic;
        background-color: $gray-300;
        color: color-contrast(theme-color("primary"), $gray-300);
        padding: 1rem;
    }
}


ul.attachments {
    margin-top: 3rem;
    font-size: $font-size-sm;
}

// Ticket creation form
.ticket-entry {

}