@import "variables";


.social {
    text-align: right;
    color: #fff;
    margin: 0;
    font-size: 0;
    /*font-family: $headings-font-family;
    list-style: none;
    margin-right: 0;
    
    line-height: 32px;*/
    @include media-breakpoint-only(xs) {
        margin-bottom: 15px;
    }

    li {
        cursor: pointer;
        //text-align: center;
        margin-left: 10px;
        margin-right: 0 !important;
    }

    li > a {
        border: 2px solid $light;
        border-radius: 100% 100% 100% 100%;
        height: 3.5rem;
        width: 3.5rem;
        transition: background .5s ease-in-out, color .5s ease-in-out;
        color: white;
        display: flex;
        align-items: center;

        & > i {
            flex: 1 0 0;
            text-align: center;
            font-size: $font-size-sm;
        }
    }

    li.facebook a {
        background-color: $social-facebook-color;
    }

    li.twitter a {
        padding: 1px 0 0 2px;
        background-color: $social-twitter-color;
    }

    li.linkedin a {
        padding: 1px 0 0 2px;
        background-color: $social-linkedin-color;
    }

    @media (hover:hover) {

        li.facebook a, li.twitter a, li.linkedin a {
            background-color: transparent;
        }


        li:hover a {
            color: #fff;
        }

        li.facebook:hover a {
            background-color: $social-facebook-color;
        }

        li.twitter:hover a {
            background-color: $social-twitter-color;
        }

        li.linkedin {
            /*line-height: 26px;*/
            &:hover a {
                background-color: $social-linkedin-color;
            }
        }
    }
}

