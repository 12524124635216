﻿@import "_portal_variables";

.tools {
    margin-top: 1.5rem;
    width: 100%;
    text-align: right;


    &:after { /* clearfix */
        display: block;
        clear: both;
        content: "";
    }

    form {
        display: inline-flex;
    }

    a + a, a + .btn, a + form, form + a {
        margin-left: 2rem;
    }

    .muted {
        color: $text-muted;
        font-size: $font-size-sm;
        padding-right: ($grid-gutter-width / 2);
    }
}

.card-footer {
    .tools {
        margin-top: 0;
    }
}