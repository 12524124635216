﻿@import "support_variables";


.status.widget {
    .spb {
        display: flex;
        flex-direction: row;

        & > i, & > span {
            flex: 0 0 auto;
        }

        & > span {
            align-self: center;
            padding-left: 2rem;
        }
    }

    .spf {
        margin-top: 2rem;
        color: $text-muted;
        font-size: $font-size-sm;
    }

    &.success {
        .far {
            color: theme-color("success");
            font-size: 4rem;
        }
    }

    &.warning {
        .far {
            color: theme-color("warning");
            font-size: 4rem;
        }
    }
}
